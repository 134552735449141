<template>
  <div class="container">
    <div class="section">
      <div class="section-title">缴费项目</div>
      <div class="section-item-wrap">
        <div class="section-item" @click="goToUtilityBill">
          <img src="../../assets/icon6.png" />
          <div>往来款5138账户</div>
        </div>
        <div class="section-item" @click="goToRentBill">
          <img src="../../assets/other_cost.png" />
          <div>自有资金1963账户</div>
        </div>
        <div class="section-item" @click="goToAbcBank">
          <img src="../../assets/icon5.png" />
          <div>其他</div>
        </div>
      </div>
    </div>
    <div class="description">
      <p>说明：<br>1.往来款5138账户：水电费、物管费、普通话报名费、英语应用能力报名费<br>
        2.自有资金1963账户：房租、考试费、培训费
      </p>
    </div>
  </div>
</template>

<script>
import { Grid, GridItem, Dialog } from "vant";

export default {
  components: {
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Dialog.name]: Dialog,
  },

  computed: {
    school: function() {
      return this.$store.getters.school;
    },
  },

  methods: {
    goToUtilityBill() {
      const schoolNo = "30020102";
      this.$store.dispatch("home/setSchoolNo", schoolNo);
      this.$store.dispatch("home/fetchSchool", schoolNo).then((res) => {
        const schoolName = res.name;
        document.title = schoolName;
      });
      this.$router.push("/wsxy/home");
    },
    goToRentBill() {
      const schoolNo = "30020103";
      this.$store.dispatch("home/setSchoolNo", schoolNo);
      this.$store.dispatch("home/fetchSchool", schoolNo).then((res) => {
        const schoolName = res.name;
        document.title = schoolName;
      });
      this.$router.push("/wsxy/home");
    },
    goToAbcBank() {
      window.location.href="https://enjoy.abchina.com/jf-web/epayItem?code=JF-EPAY2022041194415";
    },
  },

  mounted() {
    document.title = "文山学院";
  },
};
</script>

<style lang="less" scoped>
.container {
  height: 100vh;
  background: #eee;
  .section {
    background: #fff;
    margin-bottom: 20px;
    .section-title {
      position: relative;
      line-height: 84px;
      box-sizing: border-box;
      padding-left: 38px;
      font-size: 34px;
      color: #333;
      &:before {
        content: "";
        display: inline-block;
        width: 6px;
        height: 24px;
        background: #4888f4;
        margin-right: 20px;
      }
      &:after {
        position: absolute;
        content: "";
        background-color: #bfbfbf;
        display: block;
        height: 2px;
        transform: scale(1, 0.5);
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
    .section-item-wrap {
      .section-item {
        display: inline-block;
        box-sizing: border-box;
        height: 174px;
        width: 230px;
        text-align: center;
        padding-top: 50px;
        img {
          width: 46px;
          height: 46px;
        }
        div {
          font-size: 24px;
          color: #333;
          padding-top: 16px;
        }
      }
    }
  }
  .description {
    p {
      padding: 5px;
      font-size: 22px;
      line-height: 35px;
    }
  }
}
</style>
