<template>
  <div class="container">
    <img src="../assets/success.png" />
    <div class="status">缴费成功</div>
    <div class="link" @click="goToPaymentHistory">点击此处查看缴费记录</div>
    <div class="tip">缴费结果通知到财务系统方可进行下一笔交付</div>
  </div>
</template>
<script>
export default {
  methods: {
    goToPaymentHistory() {
      this.$router.push("paymentHistory");
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  text-align: center;
  padding-top: 174px;
  img {
    width: 292px;
  }
  .status {
    font-size: 42px;
    font-weight: 500;
    color: #333;
    line-height: 120px;
  }
  .link {
    font-size: 28px;
    color: #0066ff;
    text-decoration: underline;
  }
  .tip {
    margin-top: 60px;
    font-size: 26px;
    color: #666;
  }
}
</style>
