import axios from "axios";
import store from "../store";
import { api } from "../config";
import { Toast } from "vant";

axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";

// create an axios instance
const service = axios.create({
  baseURL: api.base_api, // url = base url + request url
  withCredentials: false, // send cookies when cross-domain requests
  timeout: 30000, // request timeout
});

// request拦截器 request interceptor
service.interceptors.request.use(
  (config) => {
    // 不传递默认开启loading
    if (!config.hideloading) {
      // loading
      Toast.loading({
        forbidClick: true,
      });
    }
    if (store.getters.token) {
      config.headers["Authorization"] = "Bearer " + store.getters.token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    Toast.clear();
    if (response.status >= 200 && response.status < 300) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    Toast.clear();
    if (error.response.status === 401) {
      store.dispatch("user/fedLogOut").then(() => {
        location.reload();
      });
    }
    return Promise.reject(error.response);
  }
);

export default service;
