<template>
  <div class="container">
    <img src="../assets/bg1.png" />
    <van-form class="form" @submit="onSubmit">
      <div class="form-item">
        <img class="user" src="../assets/user.png" />
        <van-field
          v-model="state.student_name"
          placeholder="请输入姓名"
          :rules="[{ required: false, message: '请输入姓名' }]"
        />
      </div>
      <div class="form-item">
        <img class="password" src="../assets/password.png" />
        <van-field
          v-model="state.student_no"
          name="学号或身份证号"
          placeholder="输入学号或身份证号"
          :rules="[{ required: false, message: '输入学号或身份证号' }]"
        />
      </div>
      <div class="btn">
        <van-button round block type="primary" native-type="submit">
          登录
        </van-button>
      </div>
    </van-form>
    <div class="bottom">
      <img class="left" src="../assets/bg2.png" />
      <img class="right" src="../assets/bg3.png" />
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";
import { useStore } from "vuex";
import { Form, Field, Button, Dialog } from "vant";

export default {
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Button.name]: Button,
    [Dialog.name]: Dialog,
  },

  setup() {
    const store = useStore();
    const state = reactive({
      student_name: "",
      student_no: "",
    });
    const onSubmit = () => {
      store.dispatch("home/bindStudent", state);
    };

    return {
      state,
      onSubmit,
    };
  },
};
</script>

<style lang="less" scoped>
.container {
  height: 100vh;
  img {
    width: 100%;
    height: 390px;
  }
  .form {
    margin: 84px 90px 0;
    .form-item {
      display: flex;
      position: relative;
      font-size: 28px;
      .van-cell {
        padding: 32px 32px 20px;
      }
      &:after {
        position: absolute;
        content: "";
        background-color: #bfbfbf;
        display: block;
        height: 2px;
        transform: scale(1, 0.5);
        bottom: 0;
        left: 0;
        right: 0;
      }
      img {
        margin-top: 40px;
      }
      img.user {
        width: 34px;
        height: 36px;
      }
      img.password {
        width: 38px;
        height: 28px;
      }
    }
    .btn {
      margin-top: 120px;
      font-size: 38px;
      font-weight: bold;
    }
  }
  .bottom {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    .left,
    .right {
      position: absolute;
      bottom: 0;
    }
    .left {
      left: 0;
      width: 368px;
      height: 270px;
    }
    .right {
      right: 0;
      width: 160px;
      height: 188px;
    }
  }
}
</style>
