<template>
  <div class="container">
    <div class="header">
      <div>姓名：{{ student.name }}</div>
      <div>身份识别号：{{ student.no }}</div>
      <div>班级：{{ student.class_in }}</div>
    </div>
    <div class="tip">说明：本系统支持查询近两年的缴费记录</div>
    <div class="history-wrap">
      <block v-if="historys.length">
        <block v-for="history in historys" :key="history.id">
          <div class="history">
            <div class="history-item">
              <div class="history-item__label">缴费时间：</div>
              <div class="history-item__value">
                {{ history.paid_at.split(" ")[0] }}
              </div>
            </div>
            <div class="history-item">
              <div class="history-item__label">缴费项目：</div>
              <div class="history-item__value">{{ history.name }}</div>
            </div>
            <div class="history-item">
              <div class="history-item__label">缴费金额：</div>
              <div class="history-item__value">￥{{ history.paid_amount }}</div>
            </div>
            <div class="history-item">
              <div class="history-item__label">支付方式：</div>
              <div class="history-item__value">{{ history.channel }}</div>
            </div>
          </div>
        </block>
      </block>
      <div v-else class="empty">暂无缴费记录</div>
    </div>
    <div class="btn">
      <van-button block type="primary" to="home">
        返回首页
      </van-button>
    </div>
  </div>
</template>

<script>
import { Button } from "vant";
import { fetchPaymentHistory } from "@/api/paymentHistory";

export default {
  components: {
    [Button.name]: Button,
  },

  data() {
    return {
      historys: [],
    };
  },

  computed: {
    student() {
      return this.$store.getters.student;
    },
  },

  mounted() {
    const { school, student } = this.$store.getters;
    fetchPaymentHistory(school.id, student.id).then((res) => {
      this.historys = res.data || [];
    });
  },
};
</script>
<style lang="less" scoped>
.container {
  .header {
    width: 100%;
    height: 316px;
    box-sizing: border-box;
    padding: 100px 0 0 60px;
    background: url(../assets/cost_bg.png) no-repeat;
    background-size: 100% 100%;
    div {
      font-size: 28px;
      line-height: 54px;
      color: #666;
    }
  }
  .tip {
    line-height: 80px;
    padding: 0 26px;
    font-size: 26px;
    color: #ff3333;
  }
  .history-wrap {
    height: calc(100vh - 556px);
    overflow: scroll;
    box-sizing: border-box;
    padding: 0 26px;
    .history {
      padding: 20px 0;
      border-bottom: 2px solid #ccc;
      .history-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 26px;
        line-height: 30px;
        color: #333;
        margin-bottom: 30px;
      }
    }
  }
  .empty {
    text-align: center;
    color: #666;
    line-height: 400px;
  }
  .btn {
    position: fixed;
    left: 26px;
    right: 26px;
    bottom: 60px;
    button {
      border-radius: 16px;
    }
  }
}
</style>
