<template>
  <div class="cost-container">
    <div class="header">
      <div class="info">
        {{ school.name }}
        <span>{{ student.name }}</span>
      </div>
      <div class="tip">班级：{{ student.class_in }}</div>
    </div>
    <div class="content">
    <block v-if="costs.length">
      <div class="cost-wrapper">
        <block v-for="cost in costs" :key="cost.year">
          <div class="cost" @click="goToCost(cost)">
            <div class="cost-left">
              <div class="year">{{ cost.year }}学年度费用</div>
              <div class="number">共{{ cost.items?.length || 0 }}项</div>
            </div>
            <div class="total">￥ {{ cost.total }}</div>
          </div>
        </block>
      </div>
    </block>
    <div v-else class="noTip">
        您没有需要缴纳的费用！
    </div>
    </div>
    <div class="btn">
      <van-button block plain type="primary" to="home">
        返回首页
      </van-button>
    </div>
  </div>
</template>
<script>
import { Button } from 'vant';

export default {

  components: {
    [Button.name]: Button,
  },

  computed: {
    school: function() {
      return this.$store.getters.school;
    },
    student: function() {
      return this.$store.getters.student;
    },
    costs: function() {
      return this.$store.getters.costs;
    },
  },

  methods: {
    goToCost(cost) {
      this.$store.dispatch("cost/handleCost", cost);
    },
  },

  mounted() {
    const { school, student } = this.$store.getters;
    this.$store.dispatch("cost/fetchBill", {
      schoolId: school.id,
      studentId: student.id,
    });
  },
};
</script>
<style lang="less" scoped>
.cost-container {
  min-height: 100vh;
  background: #eee;
}
.header {
  width: 100%;
  height: 316px;
  background: url(../assets/cost_bg.png) no-repeat;
  background-size: 100% 100%;
  text-align: center;
  box-sizing: border-box;
  padding: 100px 0;
  .info {
    font-size: 36px;
    color: #333;
    padding-bottom: 20px;
    margin: 0 50px;
    border-bottom: 1px solid #ccc;
    span {
      padding-left: 16px;
    }
  }
  .tip {
    font-size: 26px;
    padding-top: 20px;
  }
}
.noTip {
  line-height: 400px;
  text-align: center;
  font-size: 36px;
  color: #63ca63;
}
.cost-wrapper {
  width: 100%;
  box-sizing: border-box;
  padding: 20px 16px 0;
}
.cost {
  display: flex;
  justify-content: space-between;
  background: #fff;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 40px 20px;
  .number {
    color: #666;
  }
  .total {
    position: relative;
    align-self: center;
    display: flex;
    align-items: center;
    &:after {
      content: "";
      display: inline-block;
      width: 12px;
      height: 12px;
      border-top: 4px solid #666;
      border-right: 4px solid #666;
      transform: rotate(45deg);
      margin-left: 10px;
    }
  }
}
.btn {
    padding: 0 26px;
    button {
      margin-top: 60px;
      border-radius: 16px;
    }
  }
</style>
