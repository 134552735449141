import qs from "qs";
import wechatAuth from "./plugins/wechatAuth"; // 微信登录插件
import router from "./router";
import store from "./store";
import { api } from "./config";

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  const loginStatus = Number(store.getters.loginStatus || 0);

  if (loginStatus === 0) {
    // 微信未授权登录跳转到授权登录页面
    const url = window.location.href;
    // 解决重复登录url添加重复的code与state问题
    const parseUrl = qs.parse(url.split("?")[1]);

    let loginUrl;
    if (parseUrl.code && parseUrl.state) {
      delete parseUrl.code;
      delete parseUrl.state;
      loginUrl = `${url.split("?")[0]}?${qs.stringify(parseUrl)}`;
    } else {
      loginUrl = url;
    }
    console.log("url", loginUrl);
    // 设置微信授权回调地址
    wechatAuth.redirect_uri = api.redirect_uri + to.fullPath;
    // 无论拒绝还是授权都设置成1
    store.dispatch("user/setLoginStatus", 1);
    // 跳转到微信授权页面
    window.location.href = wechatAuth.authUrl;
  } else if (loginStatus === 1) {
    // 用户已授权，获取code
    try {
      // 通过回调链接设置code status
      wechatAuth.returnFromWechat(to.fullPath);
    } catch (err) {
      // 失败，设置状态未登录，刷新页面
      store.dispatch("user/setLoginStatus", 0);
      location.reload();
    }
    // 同意授权 to.fullPath 携带code参数，拒绝授权没有code参数
    const code = wechatAuth.code;
    if (code) {
      // 拿到code 访问服务端的登录接口
      store
        .dispatch("user/loginWechatAuth", code)
        .then(() => {
          // 成功设置已登录状态
          store.dispatch("user/setLoginStatus", 2);
          next();
        })
        .catch(() => {
          // 失败，设置状态未登录，刷新页面
          store.dispatch("user/setLoginStatus", 0);
          location.reload();
        });
    } else {
      store.dispatch("user/setLoginStatus", 0);
      location.reload();
    }
  } else {
    // 已登录直接进入
    next();
  }
});
