import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./permission";
import wechatAuth from "./plugins/wechatAuth";

createApp(App)
  .use(wechatAuth, {
    appid: "wx5bb16ec6a0fc9720",
  })
  .use(router)
  .use(store)
  .mount("#app");
