import qs from "qs";
import request from "@/utils/request";

/**
 * 登录接口请求token与userinfo
 * @param params {code: code}
 */
export function loginByCode(params) {
  return request({
    url: "/authorizations",
    method: "post",
    data: qs.stringify({
      ...params,
      client_id: "938dbf7d-83d3-4533-94a4-b0721fb346ae",
      client_secret: "PH6rgTnulI71PNKTj0s7EFwfJHnjrPwTmaKqGH0a",
      grant_type: "wechat-social",
      social_scope: "snsapi_base",
    }),
  });
}
