<template>
  <div class="cost-container">
    <div class="header">
      <div class="info">
        {{ school.name }}
        <span>{{ student.name }}</span>
      </div>
      <!-- <div class="tip">说明：往期欠费交清方可进行下一期缴费</div> -->
      <div class="tip">班级：{{ student.class_in }}</div>
    </div>
    <div class="content">
      <block v-if="unpaidCosts.length">
        <div class="item-wrap">
          <block v-for="cost in unpaidCosts" :key="cost.key">
            <div class="item">
              <div class="item-header">
                <div>
                  <text>{{ cost.name }}</text>
                  <!-- <text v-if="cost.mandatory">（必交项）</text> -->
                </div>
                <div class="item-price">￥{{ cost.actual_amount }}</div>
              </div>
              <div class="item-tip">
                <text>欠费金额</text>
                <text class="unpaid">￥ {{ cost.unpaid_amount }}</text>
              </div>
            </div>
          </block>
        </div>
        <div class="total">
          <text>合计欠费金额</text>
          <text>￥ {{ total }}</text>
        </div>
      </block>
      <div v-else class="noTip">
        您没有需要缴纳的费用！
      </div>
    </div>
    <div class="btn">
      <van-button block plain type="primary" to="home">
        返回首页
      </van-button>
    </div>
  </div>
</template>
<script>
import { Button, Checkbox, Field, Popup } from 'vant';

export default {
  components: {
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [Field.name]: Field,
    [Popup.name]: Popup,
  },

  data() {
    return {
      show: false,
      bill: [],
      billItemsTxt: '',
    };
  },

  computed: {
    unpaidCosts: function() {
      return this.$store.getters.unpaidCosts;
    },
    total: function() {
      return this.$store.getters.unpaidCostsTotal;
    },
    school: function() {
      return this.$store.getters.school;
    },
    student: function() {
      return this.$store.getters.student;
    },
  },

  methods: {
    formatter(value) {
      return value ? Number(value).toFixed(2) : value;
    },
  },

  mounted() {
    const { school, student } = this.$store.getters;
    this.$store.dispatch('cost/fetchBillQuery', {
      schoolId: school.id,
      studentId: student.id,
    });
  },
};
</script>
<style lang="less" scoped>
.header {
  width: 100%;
  height: 316px;
  background: url(../assets/cost_bg.png) no-repeat;
  background-size: 100% 100%;
  text-align: center;
  box-sizing: border-box;
  padding: 100px 0;
  .info {
    font-size: 36px;
    color: #333;
    padding-bottom: 20px;
    margin: 0 50px;
    border-bottom: 1px solid #ccc;
    span {
      padding-left: 16px;
    }
  }
  .tip {
    font-size: 26px;
    padding-top: 20px;
  }
}
.content {
  box-sizing: border-box;
  padding: 26px;
}
.item-wrap {
  .item {
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 28px;
      margin-bottom: 32px;
    }
    .item-header {
      font-size: 30px;
      color: #333;
      div:first-child {
        display: flex;
        text {
          padding-left: 12px;
        }
      }
    }
    .item-tip {
      padding-left: 46px;
      font-size: 26px;
      color: #666;
      .unpaid {
        color: #ff3333;
      }
    }
  }
}
.total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 32px;
  color: #333;
  font-weight: 500;
  padding: 50px 0;
}
.noTip {
  line-height: 400px;
  text-align: center;
  font-size: 36px;
  color: #63ca63;
}
.btn {
  padding: 0 26px;
  button {
    margin-top: 60px;
    border-radius: 16px;
  }
}
</style>
