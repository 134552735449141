<template>
  <div class="cost-container">
    <div class="header">
      <div class="info">
        {{ school.name }}
        <span>{{ student.name }}</span>
      </div>
      <div class="tip">班级：{{ student.class_in }}</div>
    </div>
    <div class="content">
      <block v-if="items.length">
        <div class="date">
          <img src="../../assets/year.png" />
          <text>年度：{{ year }} 学年</text>
        </div>
        <div class="item-wrap">
          <block v-for="item in items" :key="item.id">
            <div class="item">
              <div class="item-header">
                <div>
                  <van-checkbox
                    icon-size="15"
                    v-model="item.checked"
                    :disabled="item.mandatory"
                    @change="computedTotal"
                    >{{ item.name }}</van-checkbox
                  >
                  <!-- <text v-if="item.mandatory">（必交项）</text> -->
                </div>
                <div class="item-price">￥{{ item.actual_amount }}</div>
              </div>
              <div class="item-tip" v-if="item.checked">
                <text>欠费金额</text>
                <text class="unpaid">￥ {{ item.unpaid_amount }}</text>
              </div>
              <div class="item-operate" v-if="item.checked && item.partial">
                <text>输入本次支付金额</text>
                <div>
                  <span>￥</span>
                  <van-field
                    v-model="item.charge_amount"
                    maxlength="7"
                    type="number"
                    placeholder="请输入"
                    :formatter="formatter"
                    format-trigger="onBlur"
                    @blur="computedTotal"
                  />
                </div>
              </div>
            </div>
          </block>
        </div>
        <div class="total">
          <text>合计金额</text>
          <text>￥ {{ total }}</text>
        </div>
      </block>
      <div v-else class="noTip">
        您没有需要缴纳的费用！
      </div>
    </div>
    <div class="btn">
      <van-button
        v-if="items.length"
        block
        type="primary"
        :disabled="!allow_payments"
        @click="beforeCreateOrder"
      >
        去缴费
      </van-button>
      <!-- <van-button block plain type="primary" to="home">
        返回首页
      </van-button> -->
    </div>
    <van-popup round v-model:show="show" get-container="getContainer">
      <div class="confirm-wrap">
        <div class="confirm-title">请再次确认缴费信息</div>
        <div class="confirm-content">
          <div>姓名：{{ student.name }}</div>
          <div>班级：{{ student.class_in }}</div>
          <div>缴费项目：{{ billItemsTxt }}</div>
          <div>
            费用合计：<span>￥ {{ total }}</span>
          </div>
        </div>
        <div class="confirm-bottom">
          <van-button type="primary" @click="createOrder">
            确认缴费
          </van-button>
          <van-button plain type="primary" @click="hide">
            取消重选
          </van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { Button, Checkbox, Dialog, Field, Popup } from 'vant';

export default {
  components: {
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [Field.name]: Field,
    [Popup.name]: Popup,
  },

  data() {
    return {
      show: false,
      bill: [],
      billItemsTxt: '',
    };
  },

  computed: {
    year: function() {
      return this.$store.getters.year;
    },
    items: function() {
      return this.$store.getters.items;
    },
    total: function() {
      return this.$store.getters.total;
    },
    school: function() {
      return this.$store.getters.school;
    },
    student: function() {
      return this.$store.getters.student;
    },
    allow_payments: function() {
      return this.$store.getters.allow_payments;
    },
  },

  methods: {
    formatter(value) {
      return value ? Number(value).toFixed(2) : value;
    },
    computedTotal() {
      let sum = 0;
      this.items.forEach((item) => {
        if (item.checked && item.charge_amount) {
          sum += Number(item.charge_amount);
        }
      });
      this.$store.dispatch('cost/setTotal', sum.toFixed(2));
    },
    getContainer() {
      return document.querySelector('.cost-container');
    },
    hide() {
      this.show = false;
    },
    beforeCreateOrder() {
      let alertTxt = '';
      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i];
        if (item.checked) {
          if (
            !item.charge_amount ||
            Number(item.charge_amount) < 0 ||
            Number(item.charge_amount) > Number(item.unpaid_amount)
          ) {
            alertTxt = item.name + '本次支付金额必须大于0且小于等于欠费金额';
            break;
          }
        }
      }
      if (alertTxt) {
        Dialog.alert({
          title: '输入金额有误',
          message: alertTxt,
          confirmButtonColor: '#1989fa',
        });
        return;
      }
      const obj = [];
      const billItems = [];
      this.items.forEach((item) => {
        if (item.checked && item.charge_amount) {
          billItems.push(item.name);
          obj.push({
            id: item.id,
            no: item.no,
            name: item.name,
            charge_amount: Number(item.charge_amount),
            attach: item.attach,
          });
        }
      });

      this.bill = obj;
      this.billItemsTxt = billItems.join(',');
      this.show = true;
    },
    createOrder() {
      this.show = false;
      this.$store
        .dispatch('cost/createOrder', { items: this.bill })
        .then((res) => {
          if (res.pay_payload) {
            if (typeof WeixinJSBridge == 'undefined') {
              if (document.addEventListener) {
                document.addEventListener(
                  'WeixinJSBridgeReady',
                  this.onBridgeReady(res.pay_payload),
                  false
                );
              } else if (document.attachEvent) {
                document.attachEvent(
                  'WeixinJSBridgeReady',
                  this.onBridgeReady(res.pay_payload)
                );
                document.attachEvent(
                  'onWeixinJSBridgeReady',
                  this.onBridgeReady(res.pay_payload)
                );
              }
            } else {
              this.onBridgeReady(res.pay_payload);
            }
          }
        })
        .catch((res) => {
          Dialog.alert({
            title: '提示',
            message: res.data.message,
            confirmButtonColor: '#1989fa',
          });
        });
    },
    onBridgeReady(params) {
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        {
          appId: params.appId, //公众号名称，由商户传入
          timeStamp: params.timeStamp, //支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          nonceStr: params.nonceStr, //支付签名随机串，不长于 32 位
          package: params.package, //统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          signType: params.signType, //签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
          paySign: params.paySign, //支付签名
        },
        function(res) {
          if (res.err_msg === 'get_brand_wcpay_request:ok') {
            this.$router.push('paymentResult');
          } else if (res.err_msg === 'get_brand_wcpay_request:fail') {
            Dialog.alert({
              title: '支付提示',
              message: '支付失败！',
              confirmButtonColor: '#1989fa',
            });
          }
        }
      );
    },
  },
};
</script>
<style lang="less" scoped>
.header {
  width: 100%;
  height: 316px;
  background: url(../../assets/cost_bg.png) no-repeat;
  background-size: 100% 100%;
  text-align: center;
  box-sizing: border-box;
  padding: 100px 0;
  .info {
    font-size: 36px;
    color: #333;
    padding-bottom: 20px;
    margin: 0 50px;
    border-bottom: 1px solid #ccc;
    span {
      padding-left: 16px;
    }
  }
  .tip {
    font-size: 26px;
    padding-top: 20px;
  }
}
.content {
  box-sizing: border-box;
  padding: 26px;
  .date {
    display: flex;
    align-items: center;
    height: 74px;
    padding-bottom: 32px;
    img {
      width: 48px;
    }
    text {
      font-size: 34px;
      color: #333;
      padding-left: 22px;
    }
  }
}
.item-wrap {
  .item {
    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 28px;
      margin-bottom: 32px;
    }
    .item-header {
      font-size: 30px;
      color: #333;
      div:first-child {
        display: flex;
        text {
          padding-left: 12px;
        }
      }
    }
    .item-tip,
    .item-operate {
      padding-left: 46px;
      font-size: 26px;
      color: #666;
    }
    .item-tip {
      .unpaid {
        color: #ff3333;
      }
    }
    .item-operate {
      text {
        flex: 1;
      }
      div {
        display: flex;
        align-items: baseline;
        span {
          margin-left: auto;
        }
      }
      .van-cell {
        flex: 0 0 200px;
        padding: 0;
        border-bottom: 2px solid #ccc;
      }
    }
  }
}
.total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 32px;
  color: #333;
  font-weight: 500;
  padding: 50px 0;
  border-top: 1px solid #ccc;
}
.noTip {
  line-height: 400px;
  text-align: center;
  font-size: 36px;
  color: #63ca63;
}
.btn {
  padding: 0 26px;
  button {
    margin-top: 60px;
    border-radius: 16px;
  }
}
.confirm-wrap {
  width: 610px;
  box-sizing: border-box;
  padding: 0 32px;
  .confirm-title {
    line-height: 110px;
    text-align: center;
    font-size: 38px;
    color: #000;
    border-bottom: 1px solid #ccc;
  }
  .confirm-content {
    padding: 30px 20px 0;
    font-size: 32px;
    line-height: 60px;
    color: #666;
    span {
      color: #00cc66;
    }
  }
  .confirm-bottom {
    padding: 38px 0;
    display: flex;
    justify-content: space-between;
    .van-button--normal {
      padding: 0 54px;
    }
    .van-button {
      font-size: 36px;
      border-radius: 10px;
    }
  }
}
</style>
