import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

import getters from "./getters";
import user from "./modules/user";
import home from "./modules/home";
import cost from "./modules/cost";

const store = createStore({
  plugins: [createPersistedState()],
  modules: {
    user,
    home,
    cost,
  },
  getters,
});

export default store;
